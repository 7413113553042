<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="30%"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label='快递代号:' prop="code">
        <el-input type="text" v-model="form.code" size="small"/>
      </el-form-item>
      <el-form-item label='快递名称:' prop="name">
        <el-input type="text" v-model="form.name" size="small"/>
      </el-form-item>
      <el-form-item label='描述:' prop="descr">
        <el-input type="textarea" autosize v-model="form.descr" size="small"/>
      </el-form-item>
      <el-form-item label='是否开启:'>
        <el-switch
            v-model="form.state"
            active-color="#13ce66"
            active-text="开启"
            :active-value="1"
            inactive-color="#ff4949"
            inactive-text="禁用"
            :inactive-value="0"
        />
      </el-form-item>
      <el-form-item>
        <el-link type="primary" :underline="false" href="https://res.17track.net/asset/carrier/info/apicarrier.all.json" target="_blank">17track</el-link>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiOptionCourierMerchants} from "@/request/api";

export default {
  name: 'Edit',
  data() {
    return {
      form: {},
      rules: {
        code: [
          {required: true, trigger: 'blur', message: '快递代号不能为空'},
        ],
        name: [
          {required: true, trigger: 'blur', message: '快递名称不能为空'},
        ],
        descr: [
          {required: true, trigger: 'blur', message: '快递公司说明不能为空'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiOptionCourierMerchants(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.success(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
