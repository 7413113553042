<template>
  <el-card class="box-card">
    <el-col :span="4">
      <el-button icon="el-icon-plus" type="primary" size="small" @click="handleEdit">
        添加
      </el-button>
      <!--      <el-button icon="el-icon-delete" type="danger" @click="handleDelete" size="small">批量删除</el-button>-->
    </el-col>
    <el-col :span="20" style="text-align: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="快递代号:">
          <el-input v-model="queryForm.code" type="text" size="small" clearable placeholder="请输入快递代号"></el-input>
        </el-form-item>
        <el-form-item label="快递名称:">
          <el-input v-model="queryForm.name" type="text" size="small" clearable placeholder="请输入快递名称"></el-input>
        </el-form-item>
        <el-form-item label="是否启用:">
          <el-select
              v-model="queryForm.state"
              clearable
              placeholder="请选择状态"
              size="small"
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <el-table
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows">
      <!--      <el-table-column align="center" show-overflow-tooltip type="selection"/>-->
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="code"
          label="快递代号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="seventeen_track"
          label="17Track 编号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="快递名称"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="是否启用"
          prop="state"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.state == 1 ? '禁用' : '启用'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.state"
                :active-value="1"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :inactive-value="0"
                @change="changeStatus(row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {
  apiDelCourierMerchants,
  apiDeletePackageMail,
  apiGetCourierMerchants,
  apiGetPackageMail,
  apiOptionCourierMerchants,
  apiSetGlobalPackage
} from "@/request/api";

export default {
  name: 'courierCompany',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      languageArr: [],
      activeName: '1',
      selectRows: '',
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        code: '',
        name: '',
        state: '',
      },
      form: {
        type: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //修改状态
    changeStatus(row) {
      apiOptionCourierMerchants({
        id: row.id,
        code: row.code,
        name: row.name,
        descr: row.descr,
        state: row.state,
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.fetchData()
        } else {
          this.$message.error(res.message)
          this.fetchData()
        }
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDelCourierMerchants({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetCourierMerchants(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>

